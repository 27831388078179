import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Location } from '@angular/common';
import { Observable }     from 'rxjs';
// import 'rxjs/add/operator/switchMap';
import { mergeMap, filter, map, switchMap } from 'rxjs/operators';

import { Booking } from '../../shared/models/booking.model';
import { Service } from '../../shared/models/service.model';
import { Stylist } from '../../shared/models/stylist.model';
import { ServiceDefinition } from '../../shared/models/servicedefinition.model';
import { ServiceCategory } from '../../shared/models/servicecategory.model';
import { UserService } from '../../shared/services/user.service';
import { StylistService } from '../../shared/services/stylist.service';
import { ServiceCategoryService } from '../../shared/services/service-category.service';
import { SalonService } from '../../shared/services/salon.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { Utilities } from '../../shared/utilities/utilities';

@Component({
  templateUrl: 'stylist-service-list.component.html',
  styleUrls: ['stylist-service-list.component.scss']
})
export class StylistServiceListComponent implements OnInit {

  public stylist: Stylist;

  public serviceCategories: Array<ServiceCategory>;

  public isLoading: boolean;

  public utilities: Utilities = Utilities;

  constructor (private serviceCategoryService: ServiceCategoryService,
              private userService: UserService,
              private userBookingService: UserBookingService,
              private route: ActivatedRoute,
              private location: Location,
              private dialogsService: DialogsService,
              private stylistService: StylistService,
              private router: Router) {
    this.serviceCategories = [];
    this.isLoading = true;
  }

  public ngOnInit () {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          const stylistID = params['id'];
          if (stylistID === 'any') {
            return this.serviceCategoryService.getSalonServiceCategories();
          } else {
            return new Observable<Array<ServiceCategory>>((observer) => {
              const sid = parseInt(stylistID, 10);
              const salonID: number = this.userService.getSalon().getId();
              this.stylistService.getStylists(salonID, true)
                .pipe(
                  mergeMap(stylists => stylists),
                  filter(stylist => stylist.getID() === sid),
                  map((stylist: Stylist) => {
                    this.stylist = stylist;
                    return stylist.getServiceCategories()
                  })
                )
                .subscribe((serviceCategory: Array<ServiceCategory>) => {
                  observer.next(serviceCategory);
                },
                (error) => {
                  throw error;
                  // this.dialogsService.errorAlert().subscribe();
                });
            });
            // return this.serviceCategoryService.getServiceCategories(parseInt(stylistID, 10), true);          
          }
        })
      )
      .subscribe(
        serviceCategories => {

          // console.log(serviceCategories);

          this.serviceCategories = serviceCategories;
          this.isLoading = false;
        },
        error => {
          // this.alertCtrl.create(Utilities.getDefaultErrorMessageConfig()).present();
          this.dialogsService.errorAlert().subscribe();
          this.isLoading = false;
        }
      );
  }

  public onDismiss () {
    this.location.back();
  }

  public serviceSelect (serviceDefinition: ServiceDefinition) {
    const booking = this.userBookingService.getBooking();

    if (!serviceDefinition) {
      return;
    }

    const service: Service = new Service({
      serviceDefinition: serviceDefinition,
      clientNotes: '',
      stylistNotes: '',
      price: serviceDefinition.getPrice(),
      startDateTime: undefined,
      durations: {
        startDuration: serviceDefinition.getDuration(),
        processDuration: serviceDefinition.getProcessDuration(),
        finishDuration: serviceDefinition.getFinishDuration()
      },
      lineItemID: undefined,
      clientID:  undefined,
      client: undefined,
      type: 'service',
      name: serviceDefinition.getServiceName(),
      quantity: 1,
      isRefund: 0,
      taxRateType: 0,
      stylist: (serviceDefinition.getStylist() && serviceDefinition.getStylist().getID()) ? serviceDefinition.getStylist().clone(): undefined
    });
    booking.addService(service);
    // this.location.back();
    this.router.navigate(['/bookings']);                
  }


  navigate(event) {
    const newWindow = window.open(event, '_blank');
    newWindow.focus();
  }
}
