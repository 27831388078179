import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'
import { posthog } from 'posthog-js'

// Initializes posthog monitoring service

posthog.init('phc_IhMH0JCKHq92B0PbkjdpdSYxIMSnIsxClWkByWYjbBQ', { api_host: 'https://app.posthog.com', autocapture: true, disable_session_recording: true })

// Initializes Sentry error reporting  -reports to sentry.io

Sentry.init({
  dsn: 'https://eaff7cffdebd49b09663208d870f8437@o21453.ingest.sentry.io/5691597',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://salonmonster.com/api'],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new posthog.SentryIntegration(posthog, 'salonmonster-software-ltd', 5691597)
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

enableProdMode()
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
// TODO: uncomment this
// if (environment.production) {
//   enableProdMode()
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))
