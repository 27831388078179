<ng-container>
  <mat-sidenav-container *ngIf="isBookingEnabled">
    <mat-sidenav #sideNav>
      <mat-list>
        <mat-list-item *ngIf="showHome">
          <mat-divider></mat-divider>
          <button mat-button (click)="viewHomePage()">
            <mat-icon>home</mat-icon> Home
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="isBookingEnabled">
          <mat-divider></mat-divider>
          <button mat-button (click)="newBooking()">
            <mat-icon>add</mat-icon> New Booking
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="this.userService.isLoggedIn()">
          <button mat-button (click)="viewClientBookings()">
            <mat-icon>date_range</mat-icon>
            Your Bookings
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="this.userService.isLoggedIn()">
          <button mat-button (click)="viewClientProfile()">
            <mat-icon>person</mat-icon>
            Your Profile
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="isBookingEnabled">
          <button mat-button (click)="viewHelpPage()">
            <mat-icon>help</mat-icon>
            Help
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="isBookingEnabled">
          <button mat-button (click)="viewContactPage()">
            <mat-icon>phone</mat-icon>
            Contact
          </button>
        </mat-list-item>
      </mat-list>
    </mat-sidenav>

    <mat-toolbar [ngClass]="{ 'justify-center': isStylistsPage() }">
      <div fxLayout="row">
        <button
          mat-icon-button
          fxShow="false"
          fxShow.xs="true"
          fxShow.sm="true"
          (click)="sideNav.open()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-button (click)="viewHomePage()" *ngIf="showHome">
          <mat-icon>home</mat-icon> Home
        </button>
        <button mat-button (click)="newBooking()" *ngIf="isBookingEnabled">
          <mat-icon>add</mat-icon> New Booking
        </button>
        <button
          mat-button
          fxShow.xs="false"
          fxShow.sm="false"
          *ngIf="this.userService.isLoggedIn()"
          (click)="viewClientBookings()"
        >
          <mat-icon>date_range</mat-icon>
          Your Bookings
        </button>

        <button
          mat-button
          fxShow.xs="false"
          fxShow.sm="false"
          *ngIf="this.userService.isLoggedIn()"
          (click)="viewClientProfile()"
        >
          <mat-icon>contact_mail</mat-icon>
          Your Profile
        </button>
        <button
          mat-button
          fxShow.xs="false"
          fxShow.sm="false"
          *ngIf="isBookingEnabled"
          (click)="viewHelpPage()"
        >
          <mat-icon>help</mat-icon>
          Help
        </button>
        <button
          mat-button
          fxShow.xs="false"
          fxShow.sm="false"
          *ngIf="isBookingEnabled"
          (click)="viewContactPage()"
        >
          <mat-icon>phone</mat-icon>
          Contact
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onLogOut()">Log Out</button>
        </mat-menu>

        <button
          *ngIf="this.userService.isLoggedIn()"
          mat-button
          [matMenuTriggerFor]="menu"
          fxFlex="auto"
          class="smc-menu-login"
        >
          <mat-icon>person</mat-icon>
          {{
            this.userService.getUser().getFirstName() +
              " " +
              this.userService.getUser().getLastName()
          }}
        </button>

        <button
          *ngIf="!this.userService.isLoggedIn()"
          mat-button
          fxFlex="auto"
          fxFlexAlign="end"
          (click)="onLogin()"
          class="smc-menu-login"
        >
          <mat-icon>person</mat-icon> Login
        </button>
      </div>
    </mat-toolbar>

    <salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

    <router-outlet></router-outlet>
    <salonmonster-footer *ngIf="showFooter"></salonmonster-footer>
  </mat-sidenav-container>
  <div style="text-align: center" *ngIf="!isBookingEnabled && !isLoading">
    <div id="no-online-booking">
      <img
        src="https://salonmonster.com/images/online-booking.png"
        class="no-online-booking-image"
      />
      <h2>Online booking is not currently available.</h2>
      <h3 *ngIf="salon?.phone">Please contact us at {{ salon.phone }}</h3>
    </div>
  </div>

  <!-- Cookie Consent Banner -->
  <div *ngIf="showCookieBanner" class="cookie-banner">
    <div class="cookie-content">
      Welcome! We use cookies to streamline your booking process and remember your preferences. 
      By continuing, you agree to our <a href="https://salonmonster.com/privacy-policy/" target="_blank">cookie policy</a>.
    </div>
    <button class="cookie-accept-btn" (click)="acceptCookies()">OK</button>
  </div>
</ng-container>
