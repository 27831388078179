// tslint:disable-next-line:import-blacklist
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ErrorHandlerService } from "../services/error-handler.service";
import { UserService } from "../services/user.service";
import { SalonmonsterHttpClient } from "../services/salonmonster-http-client";
import { environment } from "../../../environments/environment";

@Injectable()
export class StripeService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) {
    super(http);
  }

  public getCustomerCardInfo(params): Observable<any> {
    const url = `${environment.API_ROOT}/stripe/terminal/customer_card`;
    const { clientID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID: clientID }).subscribe(
        (res: any) => {
          const response = res.data;
          observer.next(response);
          observer.complete();
          // console.log('stripe.service response: ' +  response);
        },
        (err) => {
          // console.log(err);
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      );
    });
  }

  public clientIntentSecret(params): Observable<any> {
    const url = `${environment.API_ROOT}/stripe/intent_secret`;
    const { clientID, newClient } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID, newClient }).subscribe(
        (res: any) => {
          const response = res.data;
          observer.next(response.clientSecret);
          observer.complete();
        },
        (err) => {
          // console.log(err);
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      );
    });
  }

  public getClientStripeID(params): Observable<any> {
    const url = `${environment.API_ROOT}/stripe/client_stripeID`;
    const { clientID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID }).subscribe(
        (res: any) => {
          const response = res.data;
          observer.next(response);
          observer.complete();
        },
        (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      );
    });
  }

  public getPaymentMethods(params): Observable<{ res: object }> {
    const url = `${environment.API_ROOT}/stripe/client_payment_methods`;
    const { clientID } = params;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { clientID }).subscribe(
        (res: any) => {
          const response = res.data;
          observer.next(response);
          observer.complete();
        },
        (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      );
    });
  }

  public chargeSavedCard(params): Observable<any> {
    const url = `${environment.API_ROOT}/stripe/terminal/charge_saved_card`;
    const { amount, currency, clientID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { amount, currency, clientID }).subscribe(
        (res: any) => {
          const response = res.data;
          observer.next(response);
          observer.complete();
        },
        (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      );
    });
  }

  public stripePaymentType(brand, funding) {
    let payment: { type: number; name: string; amount: number };
    if (funding === "debit") {
      payment = { type: 12, name: "StripeDebit", amount: 0 };
    } else if (funding === "credit") {
      switch (brand) {
        case "visa":
          payment = { type: 13, name: "StripeVisa", amount: 0 };
          break;
        case "mastercard":
          payment = { type: 14, name: "StripeMastercard", amount: 0 };
          break;
        case "amex":
          payment = { type: 15, name: "StripeAmex", amount: 0 };
          break;
        case "diners":
          payment = { type: 16, name: "StripeDiners", amount: 0 };
          break;
        case "discover":
          payment = { type: 17, name: "StripeDiscover", amount: 0 };
          break;
        case "jcb":
          payment = { type: 18, name: "StripeJcb", amount: 0 };
          break;
        case "unionpay":
          payment = { type: 19, name: "StripeUnionpay", amount: 0 };
          break;
        default:
          payment = { type: 20, name: "StripeUnknown", amount: 0 };
      }
    } else if (funding === "prepaid") {
      payment = { type: 21, name: "StripePrepaid", amount: 0 };
    } else {
      payment = { type: 20, name: "StripeUnknown", amount: 0 };
    }
    return payment;
  }

  public attachPaymentMethod(params) {
    const url = `${environment.API_ROOT}/stripe/attach_payment`;
    const { paymentMethodID, clientID, newClient, clientName } = params;
    return new Observable<any>((observer) => {
      this.post(url, {
        paymentMethodID,
        clientID,
        newClient,
        clientName,
      }).subscribe({
        next: (res: any) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }
}
