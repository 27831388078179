import { Component, OnInit, ɵcoerceToBoolean } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

import { Booking } from '../../shared/models/booking.model';
import { Service } from '../../shared/models/service.model';
import { ServiceDefinition } from '../../shared/models/servicedefinition.model';
import { Salon } from '../../shared/models/salon.model';
import { Stylist } from '../../shared/models/stylist.model';
import { Client } from '../../shared/models/client.model';
import { UserService } from '../../shared/services/user.service';
import { SalonService } from '../../shared/services/salon.service';
import { StylistService } from '../../shared/services/stylist.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';


import * as momentTZ from 'moment-timezone';

@Component({
  selector: 'bookings-home',
  templateUrl: 'bookings-home.component.html',
  styleUrls: ['./bookings-home.component.scss']
})
export class BookingsHomeComponent implements OnInit {

  public stylists: Array<Stylist>;

  public booking: Booking;

  public showStylistPicker: boolean;
  public showAnyProvider: boolean = true;

  public today = new Date();

  public isLoading: boolean;

  public errorOccurred: boolean;

  public title: string;
  public isAnyService: boolean = false;

  constructor(private salonService: SalonService,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private stylistService: StylistService,
    private dialogsService: DialogsService,
    private router: Router,
    private route: ActivatedRoute) {
    this.stylists = [];
    this.booking = this.userBookingService.getBooking();

    if (this.booking.getServices().length === 0) {
      this.showStylistPicker = true;
    } else {
      this.showStylistPicker = false;
    }

    this.isLoading = true;
    this.title = "Your booking so far:";

  }

  public ngOnInit() {

    this.userService.getSalon()

    this.route.queryParams.subscribe(params => {
      this.isAnyService = params['any'];

    });
    this.userBookingService.getBookingChangeRef().subscribe((booking: Booking) => {
      if (booking) {
        this.booking = booking;

        this.showStylistPicker = true;
      }
    });

    // check if we came from rebooking an existing an apointment
    if (this.booking.getId() !== undefined && this.booking.getId() !== null) {
      this.userBookingService.resetBooking();
    }

    this.loadPage();
  }

  public loadPage() {
    const salon: Salon = this.userService.getSalon();
    this.isLoading = true;
    this.errorOccurred = false;
    this.showAnyProvider = !Boolean(salon.getHideAnyProvider());
    this.booking.setSalonID(salon.getId());
    this.stylistService.getStylists(salon.getId(), true)
      .subscribe(
        (stylists: Array<Stylist>) => {

          // TESTING: Simulate "single staff"
          // stylists = [stylists[0]];

          let stylistsWithBookableServiceDefinitions = stylists.filter((stylist) => {
            let stylistHasBookableServiceDefinitions: boolean = false;

            for (let serviceCategory of stylist.getServiceCategories()) {
              if (serviceCategory.getBookableServiceDefinitions().length > 0) {
                stylistHasBookableServiceDefinitions = true;
                break;
              }
            }

            return stylistHasBookableServiceDefinitions;
          });

          if (stylistsWithBookableServiceDefinitions.length === 1) {
            this.stylists = stylistsWithBookableServiceDefinitions;
          } else {
            this.stylists.push(...stylistsWithBookableServiceDefinitions);
          }

          if (this.isSingleStylistAccount()) {

            if (this.booking.getServices().length === 0) {
              this.onStylistClick(this.getSingleStylist());
              return;
            }

            this.showStylistPicker = false;
          }

          this.isLoading = false;
          this.errorOccurred = false;
        },
        (error) => {
          throw error;
          // this.dialogsService.errorAlert().subscribe();
          this.isLoading = false;
          this.errorOccurred = true;
        }
      );

  }

  public isSingleStylistAccount(): boolean {
    return this.stylists.length === 1;
  }

  public getSingleStylist(): Stylist {
    return this.stylists[0];
  }

  public addAnotherService() {

    if (this.isSingleStylistAccount()) {
      this.onStylistClick(this.getSingleStylist());
      return;
    }
    if (this.isAnyService) {
      this.router.navigate(['stylists', 'any']);
    }
    else {
      this.showAnyProvider = false;
    }
    this.showStylistPicker = true;
  }

  public removeService(service: Service) {
    this.booking.removeService(service.getId());
    if (this.booking.getServices().length === 0) {

      if (this.isSingleStylistAccount()) {
        this.onStylistClick(this.getSingleStylist());
        return;
      }

      this.showStylistPicker = true;
    }

    this.userBookingService.setBooking(this.booking);
  }

  public cancelStylistPicker() {

    if (this.booking.getServices().length === 0) {
      return;
    }

    this.showStylistPicker = false;
  }

  public onStylistClick(stylist: Stylist) {
    if (stylist?.getID() === undefined) {
      this.router.navigate(['stylists', 'any']);
    } else {
      this.router.navigate(['stylists', stylist.getID(), 'services']);
    }
  }

  public onContinue(): void {
    let navigationExtras: NavigationExtras = {};

    if (this.isAnyService) {
      navigationExtras = {
        queryParams: { any: 'true' }
      };
    }
    this.router.navigate(['stylists/availabilities'], navigationExtras);
  }

}
