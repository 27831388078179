import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

import { Booking } from "../../models/booking.model";
import { Service } from "../../models/service.model";
import { DialogsService } from "../../services/dialogs/dialogs.service";
import { Utilities } from "../../utilities/utilities";
import { environment } from "../../../../environments/environment";
// import { ImageCropperComponent, CropperSettings } from 'ng2-img-cropper';
import { ImageCroppedEvent } from "ngx-image-cropper";

interface IPhoto {
  raw: boolean;
  name: string;
  isProcessing?: boolean;
}

@Component({
  selector: "sm-booking-details",
  templateUrl: "booking-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["booking-details.component.scss"],
})
export class BookingDetailsComponent implements OnInit {
  imageChangedEvent: any = "";
  croppedImage: any = "";
  tempBase64: any = "";
  @ViewChild("imageUploadInput", { static: false }) imageUploadInput;

  @ViewChild("cropper", { static: false }) cropper: any;

  @Input() booking: Booking;

  @Input() readOnly: boolean;

  @Input() title: string;
  @Input() fromSummary: boolean;
  @Input() anyBooking: boolean;

  @Input() subTitle: string;

  @Input() showDeleteButton: boolean;

  @Input() hideNotesField: boolean;

  @Output() serviceDelete = new EventEmitter<Service>();

  @Output() photoUpdate = new EventEmitter<Array<string>>();

  public PHOTO_ROOT: string;

  public iPhotos: Array<IPhoto>;

  public isLoading: boolean;

  public clientNote: string;

  // public cropperSettings: CropperSettings;

  public data: any;

  constructor(
    private dialogsService: DialogsService,
    private cdr: ChangeDetectorRef
  ) {
    this.PHOTO_ROOT = environment.PHOTO_ROOT;
    this.iPhotos = [];
    // this.cropperSettings = new CropperSettings();
    // this.cropperSettings.width = 1024;
    // this.cropperSettings.height = 683;
    // this.cropperSettings.croppedWidth = 1024;
    // this.cropperSettings.croppedHeight = 683;
    // this.cropperSettings.canvasWidth = 400;
    // this.cropperSettings.canvasHeight = 300;
    // this.cropperSettings.noFileInput = true;
    // this.cropperSettings.preserveSize = true;
    // this.cropperSettings.cropOnResize = true;
    this.data = {};
  }

  public ngOnInit() {
    this.iPhotos = this.booking.getPhotos().map((photo) => {
      return {
        raw: Utilities.isBase64(photo),
        name: photo,
        isProcessing: false,
      };
    });
  }

  public onClientNoteUpdate(note) {
    this.clientNote = note.target.value;
    for (let service of this.booking.getServices()) {
      service.setClientNotes(note.target.value);
    }
  }

  public removeService(service: Service) {
    this.serviceDelete.emit(service);
  }

  public onAddPhoto() {
    if (this.isLoading) {
      return;
    }

    this.imageUploadInput.nativeElement.click();
  }
  public onAddNote() {
    if (this.isLoading) {
      return;
    }
    this.dialogsService.addNote().subscribe((res: string) => {
      this.clientNote = res;
      this.cdr.detectChanges();
      console.log("note", this.clientNote);
      for (let service of this.booking.getServices()) {
        service.setClientNotes(res);
      }
    });
  }

  public onImageUploadInputChange(event) {
    const file = event.target.files[0];

    this.isLoading = true;

    // insert a temporary photo with isProcessing set to true to show loading spinner
    // while processing the image
    const photo: IPhoto = {
      raw: true,
      name: "",
      isProcessing: true,
    };

    this.iPhotos.push(photo);

    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      const base64Image = myReader.result;
      var image: any = new Image();
      image.src = base64Image;
      image.onload = () => {
        this.onCrop({ base64: base64Image });
        // this.tempBase64 = base64Image;

        // imporant: so you can upload same file twice and make (change) trigger
        event.target.value = "";
      };
    };

    myReader.readAsDataURL(file);
  }

  public onCrop(event) {
    const photo = this.iPhotos[this.iPhotos.length - 1];
    photo.name = event.base64;
    photo.isProcessing = false;
    this.isLoading = false;
    this.triggerPhotoUpdate();
  }

  public onRemovePhoto(photoIndex: number) {
    this.iPhotos.splice(photoIndex, 1);
    this.triggerPhotoUpdate();
  }

  public triggerPhotoUpdate() {
    const newPhotos: Array<string> = this.iPhotos.map((iphoto) => {
      return iphoto.name;
    });

    this.photoUpdate.emit(newPhotos);
  }
}
